import React, { memo, useEffect, useState } from "react"
import { Carousel } from "antd"
import { useStaticQuery, graphql } from "gatsby"
const News = ({ isAbout, career }) => {
  let products
  let projects
  if (typeof window !== "undefined") {
    products = window.location.pathname.includes("/products")
    projects = window.location.pathname.includes("/projects")
  }
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        autoplay: false,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 1500,
        },
      },
    ],
  }

  const [articles, setArticles] = useState([])
  const fecthApiData = async url => {
    try {
      const res = await fetch(url)
      const data = await res.json()
      setArticles(data.articles)
    } catch (error) { }
  }

  useEffect(() => {
    const API =
      "https://newsapi.org/v2/top-headlines?sources=techcrunch&apiKey=c3ab41d7df654b888d301b25bac67221"
    fecthApiData(API)
  }, [])
  const queryData = useStaticQuery(graphql`
    query NewsQuery {
      Bigjerryfencing: file(relativePath: { eq: "news/Bigjerryfencing.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yazoPOS1: file(relativePath: { eq: "news/yazoPOS1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      visiopack2: file(relativePath: { eq: "news/visioedge_card.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      learningcircles: file(relativePath: { eq: "news/Circle.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bootcamp: file(relativePath: { eq: "news/bootcamp.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      good_environement4: file(
        relativePath: { eq: "news/good_environement4.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div
      className="news-container"
      style={{
        backgroundColor: `${!career || products || projects ? "#ffffff" : "#f0f2f5"
          }`,
      }}
    >
      <div className="news-Heading-container">
        <div className="heading-postion ">
          {!isAbout ? (
            <span className="news-heading">News & Insights</span>
          ) : (
            <span
              className="news-heading"
              style={{
                fontWeight: `${!career ? "400" : "600"}`,
                color: "#00000",
              }}
            >
              {" "}
              want to discover more?
            </span>
          )}
        </div>
      </div>
      <Carousel {...settings} >
        <div>
          <a href="https://bigjerrysfencing.com/">
            <div
              className=" new-card-size news-card1"
              style={{
                backgroundImage: `url(${queryData.Bigjerryfencing?.childImageSharp.fluid.src})`,
              }}
            ></div>
          </a>
          <p>
            Successfully Delivered and Complete CRM Solution for Big Jerry's
            Fencing Company.
          </p>
        </div>
        <div>
          <a href="https://yazo.com.au">
            <div
              className=" new-card-size news-card2"
              style={{
                backgroundImage: `url(${queryData.yazoPOS1?.childImageSharp.fluid.src})`,
              }}
            ></div>
          </a>
          <p>
            YAZO a POS product Launched in Autralia, ready to take on new
            customers
          </p>
        </div>
        <div>
          <div
            className=" new-card-size news-card3"
            style={{
              backgroundImage: `url(${queryData.visiopack2?.childImageSharp.fluid.src})`,
            }}
          ></div>
          <p>
            VisioEdge is Launched in Pakistan, a localized cost effective ERP
            solution based on Odoo Community
          </p>
        </div>

        <div>
          <div
            className=" new-card-size news-card4"
            style={{
              backgroundImage: `url(${queryData.learningcircles?.childImageSharp.fluid.src})`,
            }}
          ></div>
          <p>
            Learning circles, a HR initiatives for employee's knowledge
            enhancement
          </p>
        </div>
        <div>
          <div
            className=" new-card-size news-card5"
            style={{
              backgroundImage: `url(${queryData.bootcamp?.childImageSharp.fluid.src})`,
            }}
          ></div>
          <p>
            Comming Soon, A robust bootcampe for anyone who want's to be a
            successfull Mobile Developer
          </p>
        </div>
        <div>
          <div
            className=" new-card-size news-card6"
            style={{
              backgroundImage: `url(${queryData.good_environement4?.childImageSharp.fluid.src})`,
            }}
          ></div>
          <p>
            Moved to a better and bigger office, we are committed to provide the
            working environement
          </p>
        </div>
      </Carousel>
    </div>
  )
}
export default memo(News)
