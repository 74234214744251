import React, { memo, useState } from "react"

import InfoModal from "./InfoModal"
import { Button } from "antd"
import arrow from "../images/arrow.png"
const SimplifiedStepstoEngageDevelopersWithUs = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <div>
            <div className="SimplifiedStepstoEngageDevelopersWithUs">
                <h1>Simplified Steps to Engage Developers with Us</h1>
                <div className="div-container">
                    <div class="div1">
                        <h2>01</h2>
                        <p className="underlined-paragraph">
                            <strong>Let us know your requirements</strong>
                        </p>
                        <p>
                            Share your project goals. We’ll swiftly reach out to discuss how
                            we can help.
                        </p>
                        <Button className="button-our" onClick={showModal}>
                            Let’s Discuss
                            <img src={arrow} width="20px" style={{ marginLeft: "4px" }} alt="Discuss arrow"/>
                        </Button>
                    </div>
                    <div class="div2">
                        <h2>02</h2>
                        <p className="underlined-paragraph">
                            <strong>Connect with our consultant</strong>
                        </p>
                        <p>
                            Our consultant will dive into your project details, aligning our
                            services with your needs.
                        </p>
                    </div>
                    <div class="div3">
                        <h2>03</h2>
                        <p className="underlined-paragraph">
                            <strong>Meet our developer</strong>
                        </p>
                        <p>
                            Engage with your selected developer, matching their expertise to
                            your project for seamless collaboration.
                        </p>
                    </div>
                    <div class="div4">
                        <h2>04</h2>
                        <p className="underlined-paragraph">
                            <strong>Agreement Finalization</strong>
                        </p>
                        <p>
                            Finalize project terms and details with our transparent and
                            comprehensive agreement.
                        </p>
                    </div>
                    <div class="div5">
                        <h2>05</h2>
                        <p className="underlined-paragraph">
                            <strong>Continual collaboration</strong>
                        </p>
                        <p>
                            Expect ongoing support and open communication for project success.
                        </p>
                    </div>
                </div>
            </div>
            <InfoModal
                companyIsEnabled
                url="http://185.213.27.237:8066/Email/ContactUs"
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
}

export default memo(SimplifiedStepstoEngageDevelopersWithUs)
