import React, { memo } from "react"
import Img from "gatsby-image"
const Whychooseourdevelopers = ({ data }) => {
    return (
        <div className="Whychooseourdevelopers">
            <p className="heading">Why choose our developers?</p>
            <div className="why-container">
                <div class="div1">
                    <Img fluid={data.div1Img.childImageSharp.fluid} />
                    <span>
                        <h1>Proficient Expertise</h1>
                        <p>
                            Our developers brings robust proficiency, backed by a track record
                            of successful project deliveries. Staying updated with
                            cutting-edge technologies ensures optimal solutions for your
                            projects.
                        </p>
                    </span>
                </div>
                <div class="div2">
                    <Img fluid={data.div2Img.childImageSharp.fluid} />
                    <span>
                        <h1>Proven Portfolio</h1>
                        <p>
                            Our developer boasts a diverse portfolio, showcasing successful
                            projects across industries. Each project reflects a tailored
                            approach to meet unique client needs and industry standards.
                        </p>
                    </span>
                </div>
                <div class="div3">
                    <Img fluid={data.div3Img.childImageSharp.fluid} />
                    <span>
                        <h1>Tailored Solutions</h1>
                        <p>
                            Understanding your business is our priority. We collaborate
                            closely with you to craft solutions that precisely align with your
                            goals and requirements, ensuring a personalized and effective
                            approach.
                        </p>
                    </span>
                </div>
                <div class="div4">
                    <Img fluid={data.div4Img.childImageSharp.fluid} />
                    <span>
                        <h1>Timely Delivery</h1>
                        <p>
                            Punctuality is our commitment. We adhere strictly to project
                            timelines, ensuring prompt delivery without compromising on the
                            quality of work.
                        </p>
                    </span>
                </div>
                <div class="div5">
                    <Img fluid={data.div5Img.childImageSharp.fluid} />
                    <span>
                        <h1>Transparent Communication</h1>
                        <p>
                            We prioritize clear and open communication channels throughout our
                            collaboration. You'll remain informed at every stage, ensuring
                            alignment with project objectives and progress tracking.
                        </p>
                    </span>
                </div>
                <div class="div6">
                    <Img fluid={data.div6Img.childImageSharp.fluid} />
                    <span>
                        <h1>Cost-Effective Services</h1>
                        <p>
                            Our offerings strike a balance between quality and
                            cost-effectiveness. With competitive rates, we assure top-notch
                            results while optimizing your investment.
                        </p>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default memo(Whychooseourdevelopers)
