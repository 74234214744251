import React, { memo } from 'react'
import { Button, Tag } from 'antd';
import { Link } from 'gatsby';
import arrow from '../images/arrow.png'
import reactBgImg from '../images/react-js 1.png'
import dynamicsBgImg from "../images/msdynamics 1.png"
import DotNetbgImg from '../images/netcore 1.png'
import flutterbgImg from '../images/flutter 1.png'
import odoobgImg from '../images/odoo 1.png'
const OurAugmentationOfferings = () => {
    return (
        <div className='OurAugmentationOfferings'>
            <p className='heading'>Our Augmentation Offerings</p>
            <div className='our-container-offering'>
                <div class="div1">
                    <h1>React JS Experienced Developer </h1>
                    <p>Discover a wide array of top ReactJS experts meticulously selected through rigorous evaluation. Our platform offers dedicated professionals skilled in React Router, Redux, and GraphQL, empowering you to build standout web applications that outpace the competition.</p>
                    <Link to="/services/staffAugmentationLetsDiscuss?topic=rjs">
                        <Button className='button-our'
                        >Let’s Discuss
                            <img src={arrow} width='20px' style={{ marginLeft: '4px' }} className='imgarrow-size' alt='Discuss arrow'/>
                        </Button>
                    </Link>
                    <img src={reactBgImg} style={{ position: 'absolute', left: '0', bottom: '0' }} className='bg-img' alt='reactBgImg'/>
                </div>
                <div class="div2">
                    <div>
                        <span>
                            <Tag className='tab-div2' >Next.js</Tag>
                            <Tag className='tab-div2' >Gatsby</Tag>
                            <Tag className='tab-div2' >Redux</Tag>
                            <Tag className='tab-div2' >Axios</Tag>
                            <Tag className='tab-div2' >Formik</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div2' >React Router</Tag>
                            <Tag className='tab-div2' >Material-UI</Tag>
                            <Tag className='tab-div2' >ANT Design</Tag>
                            <Tag className='tab-div2' >Yup</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div2' >Chakra UI</Tag>
                            <Tag className='tab-div2' >Styled-components</Tag>
                            <Tag className='tab-div2' >React Query</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div2' >React Helmet</Tag>
                            <Tag className='tab-div2' >React Spring</Tag>
                            <Tag className='tab-div2' >Recoil</Tag>
                            <Tag className='tab-div2' >React-DnD</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div2' >React Bootstrap</Tag>
                            <Tag className='tab-div2' >React Icons</Tag>
                            <Tag className='tab-div2' >React-Intl</Tag>
                        </span>
                    </div>
                    <div>
                        <p>We specialize in Leading-edge Expertise in Key <span className='bold-text'>React JS Frameworks & Libraries</span ></p>
                    </div>
                </div>
                <div class="div3">
                    <div>
                        <p>Specializing in a Wide Array of <span className='bold-text'>D365 F&O</span> Modules and Development Aspects</p>
                    </div>
                    <div>
                        <span>
                            <Tag className='tab-div3' >X++ Programming Language</Tag>
                            <Tag className='tab-div3' >Retail Module Knowledge </Tag>
                        </span>
                        <span>
                            <Tag className='tab-div3' >Supply Chain Knowledge</Tag>
                            <Tag className='tab-div3' >Integration & Extensions</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div3' >Troubleshooting & Support</Tag>
                            <Tag className='tab-div3' >Reporting & Analytics</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div3' >manufacturing Module Knowledge</Tag>
                            <Tag className='tab-div3' >Upgrade & Migration</Tag>
                        </span>
                    </div>

                </div>
                <div class="div4">
                    <h1>D365 Retails/F&O Experienced Developer  </h1>
                    <p>Discover skilled Dynamics 365 F&O developers crafting tailored business solutions. Our platform offers skilled professionals with expertise in X++, Power Platform, and D365 integrations, enabling the seamless development of robust financial and operational systems designed to elevate your business efficiency.</p>
                    <Link to="/services/staffAugmentationLetsDiscuss?topic=drd">
                        <Button className='button-our'
                        >Let’s Discuss
                            <img src={arrow} width='20px' style={{ marginLeft: '4px' }} className='imgarrow-size' alt='Discuss arrow'/>
                        </Button>
                        <img src={dynamicsBgImg} style={{ position: 'absolute', left: '0', bottom: '0' }} className='bg-dyn-odo-img' alt='dynamicsBgImg'/>
                    </Link>
                </div>
                <div class="div5">
                    <h1>.Net Experienced Developer </h1>
                    <p>Access a pool of seasoned .NET professionals proficient in leveraging the framework's full potential. Our platform provides dedicated experts well-versed in ASP.NET, C#, and .NET Core, empowering you to develop scalable and innovative solutions that meet your business needs</p>
                    <Link to="/services/staffAugmentationLetsDiscuss?topic=ned">
                        <Button className='button-our'>Let’s Discuss
                            <img src={arrow} width='20px' style={{ marginLeft: '4px' }} className='imgarrow-size' alt='Discuss arrow'/>
                        </Button>
                    </Link>
                    <img src={DotNetbgImg} style={{ position: 'absolute', left: '0', bottom: '0' }} className='bg-img' alt='DotNetbgImg'/>
                </div>
                <div class="div6">
                    <div>
                        <span>
                            <Tag className='tab-div6' >Dapper</Tag>
                            <Tag className='tab-div6' >Serilog</Tag>
                            <Tag className='tab-div6' >Hangfire</Tag>
                            <Tag className='tab-div6' >FluentValidation</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div6' >DevExpress</Tag>
                            <Tag className='tab-div6' >Minimal APIs</Tag>
                            <Tag className='tab-div6' >JavaScript</Tag>
                            <Tag className='tab-div6' >SignalR</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div6' >Json.NET</Tag>
                            <Tag className='tab-div6' >Azure SDK for .NET</Tag>
                            <Tag className='tab-div6' >AutoMapper</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div6' >FluentValidation</Tag>
                            <Tag className='tab-div6' >ASP.NET Core Identity</Tag>
                            <Tag className='tab-div6' >CsvHelper</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div6' >Microsoft Identity Platform</Tag>
                            <Tag className='tab-div6' >RSA Encryption</Tag>
                        </span>
                    </div>
                    <div>
                        <p>Our forte lies in Advanced Proficiency across Key <span className='bold-text'>.Net Frameworks & Libraries</span></p>
                    </div>
                </div>
                <div class="div7">
                    <div>
                        <p>Focusing on a Diverse Spectrum of <span className='bold-text'>Flutter Development</span>  Expertise</p>
                    </div>
                    <div>
                        <span>
                            <Tag className='tab-div7' >Flutter Swiper</Tag>
                            <Tag className='tab-div7' >MobX </Tag>
                            <Tag className='tab-div7'>Dart</Tag>
                            <Tag className='tab-div7'>Firebase</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div7' >Flutter Hooks</Tag>
                            <Tag className='tab-div7' >GetX</Tag>
                            <Tag className='tab-div7' >RxDart</Tag>
                            <Tag className='tab-div7' >Dio</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div7' >Charts</Tag>
                            <Tag className='tab-div7' >Riverpod</Tag>
                            <Tag className='tab-div7' >Hive</Tag>
                            <Tag className='tab-div7' >Chopper</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div7' >AutoRoute</Tag>
                            <Tag className='tab-div7' >localizations</Tag>
                            <Tag className='tab-div7' >Flutter Secure Storage</Tag>
                        </span>
                    </div>

                </div>
                <div class="div8">
                    <h1>Flutter Experienced Developer </h1>
                    <p>Discover a pool of adept Flutter developers skilled in creating cross-platform mobile applications. Our platform offers dedicated experts proficient in Dart programming, Flutter framework, and UI/UX design, enabling the development of high-performing, visually appealing mobile apps that excel across various devices and platforms.</p>
                    <Link to="/services/staffAugmentationLetsDiscuss?topic=fed">
                        <Button className='button-our'>Let’s Discuss
                            <img src={arrow} width='20px' style={{ marginLeft: '4px' }} className='imgarrow-size' alt='Discuss arrow'/>
                        </Button>
                    </Link>
                    <img src={flutterbgImg} style={{ position: 'absolute', left: '0', bottom: '0' }} className='bg-img' alt='flutterbgImg'/>
                </div>
                <div class="div9">
                    <h1>Odoo Experienced Developer </h1>
                    <p>Access a talent pool of seasoned Odoo developers proficient in customizing and implementing Odoo ERP solutions. Our experts in Python, Odoo framework, and ERP customization create tailored solutions, streamlining operations for enhanced productivity.</p>
                    <Link to="/services/staffAugmentationLetsDiscuss?topic=oed">
                        <Button className='button-our'>Let’s Discuss
                            <img src={arrow} width='20px' style={{ marginLeft: '4px' }} className='imgarrow-size' alt='Discuss arrow'/>
                        </Button>
                    </Link>
                    <img src={odoobgImg} style={{ position: 'absolute', left: '0', bottom: '0' }} className='bg-dyn-odo-img' alt='odoobgImg'/>
                </div>
                <div class="div10">
                    <div>
                        <span>
                            <Tag className='tab-div10' >Odoo Framework</Tag>
                            <Tag className='tab-div10' >Odoo API Libraries</Tag>
                            <Tag className='tab-div10' >Accounting</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div10' >controllers</Tag>
                            <Tag className='tab-div10' >XML-RPC and JSON-RPC</Tag>
                            <Tag className='tab-div10' >ODOO.sh</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div10' >Object-Relational Mapping</Tag>
                            <Tag className='tab-div10' >Odoo Docker Image</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div10' >Wkhtmltopdf</Tag>
                            <Tag className='tab-div10' >JavaScript</Tag>
                            <Tag className='tab-div10' >QWeb</Tag>
                            <Tag className='tab-div10' >PostgreSQL</Tag>
                        </span>
                        <span>
                            <Tag className='tab-div10' >Command-line interface (CLI)</Tag>
                            <Tag className='tab-div10' >External APIs</Tag>
                            <Tag className='tab-div10' >Owl components</Tag>
                        </span>
                    </div>
                    <div>
                        <p>We excel in Advanced Proficiency across Vital  <span className='bold-text'>Odoo Frameworks & Libraries</span></p>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default memo(OurAugmentationOfferings)
